<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog">
			<div class="modal-bg"></div>
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Rechargement</h5>
						<button @click="back" type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div>
							<p>Solde restant sur la carte : {{balance}}€</p>
						</div>
						<div class="row flex-column flex-lg-row align-items-center">
							<div class="col mb-3">
								<label for="lastName" class="form-label">Nom</label>
								<input type="text" name="lastName" v-model="dataUser.lastName" :class="reloadStepIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateReloadCardStep">
							</div>
							<div class="col mb-3">
								<label for="firstName" class="form-label">Prénom</label>
								<input type="text" name="firstName" v-model="dataUser.firstName" :class="reloadStepIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateReloadCardStep">
							</div>
						</div>
						<div class="row flex-column flex-lg-row align-items-center">
							<div class="col mb-3">
								<label for="email" class="form-label">Email</label>
								<input type="email" name="email" v-model="dataUser.email" :class="reloadStepIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateReloadCardStep">
							</div>
						</div>
						<div class="row flex-column flex-lg-row align-items-center">
							<div>
								<label for="reloadAmount" class="form-label">Montant à recharger</label>
								<div class="mb-3">
									<button class="btn btn-outline-primary m-1" @click="changeReloadAmount(10)">10€</button>
									<button class="btn btn-outline-primary m-1" @click="changeReloadAmount(20)">20€</button>
									<button class="btn btn-outline-primary m-1" @click="changeReloadAmount(50)">50€</button>
									<button class="btn btn-outline-primary m-1" @click="changeReloadAmount(100)">100€</button>
								</div>
								<input type="text" name="reloadAmount" v-model="dataUser.reloadAmount" :class="reloadStepIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateReloadCardStep">
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" v-if="!isDisabled" @click="submitReloadCardStep">Payer</button>
						<button type="button" class="btn btn-primary btn-loader" v-else><span class="loader"></span> Paiement en cours</button>
						<button type="button" class="btn btn-secondary" data-dismiss="modal" @click="back">Annuler</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {
			reloadStepIsValid: false,
			isDisabled: false,
			balance: null,
			dataUser: {
				firstName: '',
				lastName: '',
				email: '',
				reloadAmount: ''
			}
		}
	},
	props: {
		cashEventId: {
			type: String,
			required: true
		},
		cashAccountId: {
			type: String,
			required: true
		}
	},
	methods: {
		back() {
			this.$emit('back');
		},
		closeModal() {
			this.$emit('close');
		},
		validateReloadCardStep() {
			this.reloadStepIsValid = this.dataUser.firstName !== '' && this.dataUser.lastName !== '' && this.dataUser.email !== '' && this.dataUser.reloadAmount !== '';
		},
		changeReloadAmount(amount) {
			this.dataUser.reloadAmount = amount;
			this.validateReloadCardStep();
		},
		submitReloadCardStep() {
			this.validateReloadCardStep();
			if (!this.reloadStepIsValid) return;
			this.isDisabled = true;
			console.info('submitReloadCardStep', {
				cashEventId: this.cashEventId,
				cashAccountId: this.cashAccountId,
				amount: this.dataUser.reloadAmount,
				firstName: this.dataUser.firstName,
				lastName: this.dataUser.lastName
			});
			this.$store.dispatch('reloadOnlineCashAccount', {
				cashEventId: this.cashEventId,
				cashAccountId: this.cashAccountId,
				amount: this.dataUser.reloadAmount,
				firstName: this.dataUser.firstName,
				lastName: this.dataUser.lastName,
				email: this.dataUser.email
			}).then((response) => {
				console.info(response)
				if (response.data && response.data.body.redirectUrl) {
					window.location.href = response.data.body.redirectUrl;
				} else {
					let textMessage='Une erreur inconnue a eu lieu.';
					if(response.data && response.data.error === 'CASH_ACCOUNT_NOT_FOUND'){
						textMessage = 'Vous ne pouvez pas recharger cette carte.'
					}else if(response.data && (response.data.error === 'TROPEVENT_API_ERROR' || response.data.error === 'CASH_API_ERROR')){
						textMessage = 'Il est temporairement impossible de recharger les cartes.'
					}

					this.$notify({
						title: 'Une erreur est survenue',
						text: textMessage,
						type: 'error'
					});
				}
			}).catch((e) => {
				console.info("Error "+e);
				this.$notify({
					title: 'Une erreur est survenue',
					text: 'Il est temporairement impossible de recharger les cartes.',
					type: 'error'
				});
			})
		}
	},
	mounted() {
		this.$store.dispatch('getCashAccountBalance', {
			cashAccountId: this.cashAccountId
		}).then((response) => {
			this.balance = response.data;
		})
	}
}
</script>

<style scoped>
.modal {
	display: block;
	opacity: 1;
	transition: opacity 0.15s linear;
}
.modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}
.btn-loader {
	display: flex;
	align-items: center;
	gap: .3rem;
}
.loader {
	width: 18px;
	height: 18px;
	border: 5px solid #FFF;
	border-bottom-color: #FF3D00;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
