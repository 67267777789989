import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Embed from "@/views/Embed.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/embed',
    name: 'Embed',
    component: Embed
  }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
