<template>
  <div class="container">
    <iframe :src="serverUrl + '/embed'+ getParams()"></iframe>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      serverUrl: location.origin
    }
  },
  methods: {
	getParams(){
		return location.search;	
	}
  },
  mounted() {
    window.addEventListener('message', (event) => {
      // Traitez le message reçu
      if (event.data !== undefined) {
        document.querySelector('iframe').style.height = event.data + 'px';
        document.querySelector('iframe').style.width = '100%';
      }
    }, false);
  }
}
</script>
