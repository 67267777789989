import { createStore } from 'vuex'
import axios from "axios";


if(process.env.NODE_ENV =='development'){
  CASHLESS_API = process.env.VUE_APP_API_URL;
}

/* eslint-disable no-unused-vars */
export const store = createStore({
    state: {
        token: "",
        events: []
    },
    getters: {
        events: state => state.events
    },
    actions: {
        getEvents({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(CASHLESS_API + '/api/public/cash-account/events')
                .then((response) => {
                    commit('GET_EVENTS', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
      request({ commit }, form) {
          return new Promise((resolve, reject) => {
            axios.post(CASHLESS_API + '/api/public/cash-account/requestRefund', form)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          });
      },
			reloadOnlineCashAccount({ commit }, payload) {
				return new Promise((resolve, reject) => {
					axios.post(CASHLESS_API + '/api/public/cash-account/reloadOnline', payload)
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
				});
			},
      findCashAccount({ commit }, payload) {
          return new Promise((resolve, reject) => {
            axios.post(CASHLESS_API + '/api/public/cash-account/search', payload, {
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          });
      },
			getCashAccountBalance({ commit }, payload) {
				return new Promise((resolve, reject) => {
					axios.get(CASHLESS_API + '/api/public/cash-account/' + payload.cashAccountId + '/amount')
						.then((response) => {
							resolve(response);
						})
						.catch((error) => {
							reject(error);
						});
				});
			},
      getCashOrderHistoryByCashAccount({ commit }, cashAccountId) {
          return new Promise((resolve, reject) => {
            axios.get(CASHLESS_API + '/api/public/cash-account/' + cashAccountId+'/history')
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          });
      },
      sendMail({ commit }, payload) {
          return new Promise((resolve, reject) => {
            axios.post(CASHLESS_API + '/api/public/cash-account/send-mail', payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          });
      }
    },
    mutations: {
        CONNECTED(state, token) {
          state.token = token;
        },
        GET_EVENTS(state, events) {
          state.events = events;
        }
    }
})
/* eslint-enable no-unused-vars */
