<template>
  <notifications style="margin-top: 1rem" />
  <RouterView />
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}
h2, label {
  font-weight: bold;
}
a {
  color: #f28a16;
}
</style>
