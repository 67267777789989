import { createApp } from 'vue'
import App from './App.vue'
import {store} from "@/store";
import router from "@/router";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueTelInput)
app.use(Notifications)
app.mount('#app')
